import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  return (
    <Layout>
      <SEO title="URL: 404 Not found" />
      <h1>The url not exists</h1>
    </Layout>
  )
}
